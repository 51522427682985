/* Navbar */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 21px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  height: 65px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.8rem 1.5rem;
  }
  .nav-links {
    gap: 1rem;
  }
  .sign-in-btn {
    padding: 0.3rem 0.8rem;
  }
}

.logo-image {
  height: 65px;
  width: auto;
  object-fit: contain;
}

.logo {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-links a {
  text-decoration: none;
  color: #4f4f4f;
  font-weight: 500;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #21A0A4;
}

.sign-in-btn {
  font-weight: 500;
  font-size: 1.1rem;
  background-color: transparent;
  color: #21A0A4;
  border: 2px solid #21A0A4;
  padding: 0.4rem 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  white-space: nowrap;
}

.sign-in-btn:hover {
  background-color: #21A0A4;
  color: #fff;
}

/* Hero Section */
.hero {
  background-image: url('../src/images/logo-transparent-white.png'); /* Update path as necessary */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; /* Or 'cover' depending on your preference */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 4rem;
  background: linear-gradient(135deg, #21A0A4, lightskyblue);
  color: #fff;
  min-height: 100vh;
}
.hero-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  object-fit: cover; /* Ensures the image covers the designated area */
  z-index: 0; /* Makes sure it does not interfere with other hero content */
}

.hero-logo {
  height: 180px;
  width: auto;
  margin-bottom: 2rem;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
}

.text-container {
  width: 50%;
  padding-right: 2rem;
}

.hero-intro {
  margin-top: 2.5rem;
  font-size: 2.5rem;
  line-height: 1.8;
  text-align: left;
}

.video-container {
  margin-top: 1.5rem;
  position: relative; /* Add this line if not already present */
  width: 50%;
  max-width: 600px;
}


.hero-video {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}
.feature-image{

}

.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  opacity: 0.8;
}

.video-controls {
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.control-buttons .control-btn {
  background: transparent;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.control-btn img {
  width: 20px;
  height: 20px;
}

/* About Section */
.about {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.about h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2x2 grid layout */
  gap: 20px;
  padding: 20px;
}

.feature {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  cursor: pointer; /* To indicate it's clickable */
  position: relative;
}

.feature img:first-of-type {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.feature:last-child {
  margin-left: 25%;
  width: 50%;
  grid-column: auto / span 2; /* Makes the last item take two columns if necessary */
}

.notification-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px; /* Notification icon size */
  height: 30px;
  margin: 5px;
}


.feature h3 {
  font-size: 1.5rem;
  color: #21A0A4;
  margin-bottom: 1rem;
}

.feature p {
  font-size: 1rem;
  color: #666;
}

/* Pricing Section */
.pricing {
  padding: 4rem 2rem;
  background-color: #F7F9FB;
  text-align: center;
}

.pricing h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.pricing-options {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.pricing-plan {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 300px;
  text-align: center;
}

.pricing-plan h3 {
  font-size: 1.5rem;
  color: #21A0A4;
  margin-bottom: 1rem;
}

.pricing-plan p {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

/* Contact Us Section */
/* Contact Us Section */
/* Contact Us Section */
.contact {
  padding: 0rem 2rem;
  margin-bottom: 2rem;
  background-color: #F7F9FB;
  text-align: center;
  font-family: 'League Spartan', sans-serif;
}
.email-status{
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-family: 'League Spartan', sans-serif;
}
.contact h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  font-family: 'League Spartan', sans-serif;
}

.contact p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
  font-family: 'League Spartan', sans-serif;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  font-family: 'League Spartan', sans-serif;
}

.form-group {
  display: flex;
  justify-content: space-between;
  width: 102%;
  margin-left: 1.75%;
  font-family: 'League Spartan', sans-serif;
}

.contact-form input,
.contact-form textarea {
  padding: 1rem;
  border: 2px solid #21A0A4;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  font-family: 'League Spartan', sans-serif;
}

.contact-form input {
  width: 48%;  /* Ensuring space between inputs without overflow */
  font-family: 'League Spartan', sans-serif;
}

.contact-form textarea {
  width: 100%;
  height: 150px;
  margin-top: 1rem;
  resize: none;
  font-family: 'League Spartan', sans-serif;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #4DD0E1;
  outline: none;
  font-family: 'League Spartan', sans-serif;
}

.contact-form button {
  background-color: #21A0A4;
  color: white;
  font-family: 'League Spartan', sans-serif;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-form button:hover {
  background-color: #1B8D8F;
  transform: scale(1.05);
  font-family: 'League Spartan', sans-serif;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-form input {
    width: 100%;  /* Full-width on smaller screens */
  }
}



/* Footer Update */
.footer {
  font-family: 'League Spartan', sans-serif;
  background: rgba(33, 160, 164, 0.59);
  color: white;
  text-align: center;
  padding: 1rem 0;
}

.footer p {
  margin: 0.2rem 0;
  font-size: 1rem;
}

.footer a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #4DD0E1;
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 4rem 2rem;
  }
  .hero-content, .video-container {
    max-width: 100%;
    width: 100%;
  }
  .hero-logo {
    height: 200px;
  }
  .hero-intro {
    font-size: 1.5rem;
  }
  .features {
    flex-direction: column;
    align-items: center;
  }
  .pricing-options {
    flex-direction: column;
    align-items: center;
  }
}
/* Simulator Section */
.simulator {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 400px;
  margin: 2rem auto;
  text-align: center;
  transition: transform 0.3s ease;
}

.simulator h3 {
  font-size: 1.8rem;
  color: #21A0A4;
  margin-bottom: 1.5rem;
}

.simulator input {
  width: 80%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 2px solid #21A0A4;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.simulator input:focus {
  border-color: #4DD0E1;
  outline: none;
}

.simulator button {
  background-color: #21A0A4;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 1rem;
}

.simulator button:hover {
  background-color: #1B8D8F;
  transform: scale(1.05);
}

.simulator p {
  font-size: 1.2rem;
  color: #333;
  margin-top: 1rem;
}

.simulator p:last-child {
  font-size: 0.9rem;
  color: #777;
  margin-top: 0.5rem;
}


